import React from 'react'
import SkillsGroup from './SkillsGroup'

export default function SkillsArea ({skills}) {

  return (
    <div className="skills__area">
        <h3 className="skills__title">{skills.title}</h3>   
        <SkillsGroup list={skills.skills} />
          
    </div>
  )
}
