import React from 'react'
import FilterContents from './FilterContents'
import FilterTabs from './FilterTabs'


function Main() {
  return (
    <main className="main">
      <section className="filters container">
        {/*=============== FILTERS TABS ===============*/}
        <FilterTabs />
        <FilterContents />

      </section>
    </main>
  )
}

export default Main