const SkillsList = [
    {
        "uid": "0a9a5146-115f-494b-8b37-88a63a93db59",
        "title": "Skills / Technologies",
        "skills": [
            "JavaScript", "Python", "DevOps", "Cloud Security", "Web Security", "Network Security",
             "React", "Automation ", "CI/CD", "AWS, Azure", "Burp Suite", "Red Teaming"
        ]
    },
    {
        "uid": "400b896b-69a3-4470-8ad5-586133200a87",
        "title": "Certificates",
        "skills": ["CompTIA Security+", "Google Cybersecurity Certification", "Google IT Support ", "Google IT Automation with Python"
        ]

    }
]

export default SkillsList;