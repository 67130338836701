import React from 'react'

export default function Footer(props) {
    return (
        <footer className="footer container">
            <span className="footer__copy">
                &#169; Huy Phu. All rigths reserved | Views: {props.visits}
            </span>
        </footer>
    )
}
