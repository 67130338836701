import React, {useEffect, useState} from "react"
import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import AppContext from './components/AppContext'
import SkillsList from "./assets/content/SkillsList";

function App() {
  const [activeIndex, setActiveIndex]=useState(0)
  const [websiteVisits, setWebsiteVisits]= useState(0)
  const API_URL = 'https://u53ey3bptczae2t2fgif4lq6vq0qeqvv.lambda-url.us-west-2.on.aws/'

  useEffect(() => {
    const fetchWebSiteVisits = async () => {
      const response = await fetch(API_URL)
      let count = await response.json()
      setWebsiteVisits(count)
    }
    fetchWebSiteVisits()
  },[])
  
  const userSettings = {activeIndex, setActiveIndex, SkillsList}
  return (
    <AppContext.Provider value={userSettings}>
      {/* <DisplayResume/> */}
      <Header/>
      <Main/> 

      <Footer visits={websiteVisits}/>

    </AppContext.Provider>
  );
}

export default App;
