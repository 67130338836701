import {images} from '../constant'

const ProjectsList = [
    {
        "uid":"caf76066-ffd6-4b8f-86e9-b55a200132d2",
        "title":" [Cloud Security] - AWS Certified Security (Ongoing)",
        "subtitle":" AWS, S3, EC2, Amazon GuardDuty",
        "url":"https://github.com/hhphu/CloudSecurity/tree/main/AWS/aws-certified-security",
        "image": images.cloud_monitoring
    },

    {
        "uid":"4a0d5924-e9c6-4e7a-8f79-b36014ff441d",
        "title":" [Cloud Security] - Enterprise Security Architecture",
        "subtitle":" AWS, S3, CloudWatch, IAM, Incident Response, Lambda",
        "url":"https://github.com/hhphu/CloudSecurity/tree/main/AWS/security-architect",
        "image": images.security_architecture
    },

    {
        "uid":"0a9a5146-115f-494b-8b37-88a63a93db59",
        "title":" [Cloud Security] - Secure Sentinels SIEM Infrastructure",
        "subtitle":" Azure, Sentinnels, PowerShell, Windows",
        "url":"https://www.hqphu.com/posts/6kAWd9Mm6i9jXrkiE90tct",
        "image": images.siem
    },

    {
        "uid":"cc95526f-fa88-4bce-91f6-7571aeaf3b0a",
        "title":"[Security] - Corporation Security Audits",
        "subtitle":" Nessus, NIST Frameworks, CVE, OSINT",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/corporation-security-audits",
        "image": images.vulnerability_assessment
    },

    {
        "uid":"23dca6ac-164f-44d6-a389-121b110de93d",
        "title":"[Security] - Red Teaming Operation",
        "subtitle":" Nesus, Nmap, OSINT, Metasploit, Hydra, Hashcat",
        "url":"https://www.hqphu.com/posts/36M9BpxDluSAnvX47ayNjX",
        "image": images.redteam
    } ,

    {
        "uid":"a816d2d0-b024-4e4e-b9d9-bba6977e6544",
        "title":"[Security, AI] - AI Spam Detector",
        "subtitle":" AI, Machine Learning, Python",
        "url":"https://www.hqphu.com/posts/1YChYToY8GnhdBlgxPKEJe",
        "image": images.ai
    },

    {
        "uid": "400b896b-69a3-4470-8ad5-586133200a87",
        "title":" [Security] - Threat Intelligence",
        "subtitle": " OSINT, Shodan, Google Dork",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/corporation-OSINT",
        "image": images.osint
    },

    {
        "uid":"93e1be00-825e-4c01-ba61-8a078cbf9fcc",
        "title":" [Security] - Network Analysis",
        "subtitle":" Wireshark",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/wireshark-network-analysis",
        "image": images.wireshark
    }
    
]

export default ProjectsList;
