import React from 'react'
import ProjectsList from "../assets/content/ProjectsList"

function Projects() {
    const displayProjects = ProjectsList.map((project) => {
        return (
            <article className="projects__card filters__active" key={project.uid}>
                <img src={project.image} alt="" className="projects__img"/>
                <div className="projects__modal">
                <div>
                  <h3 className="projects__title">{project.title}</h3>
                  <a href={project.url} target="_blank" rel="noreferrer" className="projects__button button button__small">
                    <i className="ri-link"></i>
                  </a>
                  <span className="projects__subtitle">{project.subtitle}</span>
                  
                </div>
              </div>
            </article>
        )
    })
    return (
        <div className="projects__content grid filters__active" data-content id="projects">
            {displayProjects}
        </div>
  )
}

export default Projects