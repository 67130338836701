import React from "react";
import {images} from "../../assets/constant"
import SocialMedia from "./SocialMedia";
import resume from "../../assets/content/resume.pdf"

export default function Header() {
  // Create a list of titles to be displayed in the body of the Header section
  const titles = [
    {
      "id":"1477caf2-c3b5-4af5-aeb5-7d0dca861c56",
      "title": "Security Engineer",
      "years": "3+ years"
    },
    {
      "id":"57261ed9-5ed8-4652-aaaf-c7f59d9ec9f3",
      "title": "QA Engineer",
      "years": "8+ years"
    },
    {
      "id":"bbe9932e-9e29-4845-9649-9493f16e4833",
      "title": "TA Mentor",
      "years": "2+ years"
    }
  ]

  const displayTitles = titles.map(({id,title, years})=> {
    return(
      <div className="profile__info-group" id={id}>
        <h3 className="profile__info-number"> {title.split(" ")[0]} <br/> {title.split(" ")[1]}</h3>
          <p className="profile__info-description">
            {years}
        </p>    
      </div>
    )
  })


  return (
    <header className="profile container">
      {/*<i className="ri-moon-line change-theme" id="theme-button"></i> */}
      <div className="profile__container grid">
        <div className="profile__data">
          <div className="profile__border">
            <div className="profile__perfil">
              {/* Insert your image, according to the example size of the portfolio */}
              <img src={images.profile2Removebg} alt="" />
            </div>
          </div>

          <h2 className="profile__name">Huy Phu</h2>
          {/* <h3 className="profile__profession">Security Engineer</h3> */}

          <SocialMedia />
        </div>

        <div className="profile__info grid">
          {displayTitles}
        </div>

        <div className="profile__buttons">
          {/* Insert your CV */}
          <a
           href={resume}
           className="button"
           target="_blank"
           rel="noreferrer"
          >
            Resume <i className="ri-download-line"></i>
          </a>

          <div className="profile__buttons-small">
            <a
              href="https://www.credly.com/users/hhphu"
              target="_blank"
              rel="noreferrer"
              className="button button__small button__gray"
            >
              <i className="ri-award-line"></i>
            </a>
            <a
              href="mailto:harry.hphu@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="button button__small button__gray"
            >
              <i className="ri-mail-line"></i>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
