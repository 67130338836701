import React, { useContext } from 'react'
import AppContext from './AppContext'


export default function FilterTabs (){
  const myContext = useContext(AppContext)
  const filterName = ["Projects","Skills"]

  function toggleFilterStyle(index) {
    return filterName[myContext.activeIndex] === filterName[index] ? "filter-tab-active" : "";
  }

  function setFilterActive(index){
    return myContext.setActiveIndex(index)
  }

  /* ================== DISPLAY FILTERS =================*/

  const displayFilterName = filterName.map ((filter,idx) => {
      return (
        <button 
         key={idx}
         className={`filters__button ${toggleFilterStyle(idx)}`} 
         data-target={`#${filter.toLowerCase()}`} 
         onClick={()=>{
            setFilterActive(idx)
         }}>
          {filter}
        </button>
      )
  })  
  
  return (
    <ul className="filters__content">
      {displayFilterName}
    </ul>
  )
}
