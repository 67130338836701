import React, {useContext} from 'react'
import AppContext from './AppContext'
import Projects from './Projects'
import Skills from './Skills'

export default function FilterContents() {
    const myContext = useContext(AppContext)
    return (
        <div className="filters__sections">   
            {myContext.activeIndex === 0 ? <Projects/> : <Skills/>}    
        </div>
    )
}
